import { useContentfulInspectorMode } from "@contentful/live-preview/react";
import type { Document } from "@contentful/rich-text-types";
import { InfoCircledIcon } from "@radix-ui/react-icons";

import {
  ABSOLUTE_POSITION,
  ITEMS_POSITION,
  JUSTIFY_POSITION,
} from "~/lib/constants";
import { cn } from "~/lib/ui";
import { useMediaScreen } from "~/lib/utils/screens";

import type { BannerContent } from "../types";
import { getMaxWidthClass } from "../util";
import { ContentfulCTA } from "./contentful-cta";
import { ContentfulPicture } from "./picture";
import { RichTextRender } from "./rich-text";
import TooltipWithRichText from "./tooltip-with-rich-text";

export const ContentfulBanner = ({
  content,
  className = "",
  buttonClassname = "",
  textClassname = "",
}: {
  content: BannerContent;
  className?: string;
  buttonClassname?: string;
  textClassname?: string;
}) => {
  const { isMobile } = useMediaScreen();
  const fields = content.fields;
  const inspectorProps = useContentfulInspectorMode();
  if (!fields.image) return null;

  // Get the Tailwind class for maxWidth
  const maxWidthClass = getMaxWidthClass(fields.maxWidth);

  const contentPosition = isMobile
    ? "center"
    : (fields.contentPosition ?? "center");
  const headingColor = fields.headingColor ?? "#FFFFFF";

  return (
    <div className={`${className} ${maxWidthClass} mx-auto`}>
      <div className="relative flex h-full w-full flex-col">
        <div
          className={"w-full rounded-lg"}
          {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}
        >
          <ContentfulPicture
            image={fields.image}
            className={className}
            isBanner
          />
        </div>
        <div
          className={`absolute mt-[15px] h-full px-[72px] py-10 md:px-[75px] lg:px-[130px] xl:px-[200px] ${ABSOLUTE_POSITION[contentPosition]} m-auto mt-0 w-fit`}
        >
          <div
            className={cn(
              `flex h-full flex-col justify-between`,
              `items-center justify-end gap-[30px] text-center md:justify-center`,
            )}
          >
            <div
              className={`flex flex-col ${JUSTIFY_POSITION[contentPosition]} ${ITEMS_POSITION[contentPosition]}`}
            >
              {fields.heading && (
                <p
                  className={cn(
                    "mb-2 w-full font-roboto text-[56px] font-black italic leading-[56px] lg:text-[86px] lg:leading-[78px] xl:text-[130px] xl:leading-[120px]",
                    textClassname,
                  )}
                  style={{ color: headingColor }}
                >
                  {fields.heading}
                </p>
              )}
              {fields.richText && (
                <div className="w-full max-w-xl">
                  {fields.richText &&
                    fields.richText.map(richContent => (
                      <RichTextRender
                        key={richContent.sys.id}
                        content={richContent.fields.richText as Document}
                        entryId={richContent.sys.id}
                        color={richContent.fields.textColour ?? "#FFFFFF"}
                        textAlignment={
                          richContent.fields.textAlignment as
                            | CanvasTextAlign
                            | undefined
                        }
                        textClassname={textClassname}
                      />
                    ))}
                </div>
              )}
            </div>

            <div
              className={`flex flex-col ${JUSTIFY_POSITION[contentPosition]} ${ITEMS_POSITION[contentPosition]}`}
            >
              <div
                className={`flex w-full max-w-xl flex-col flex-wrap gap-4 md:flex-row ${JUSTIFY_POSITION[contentPosition]} ${ITEMS_POSITION[contentPosition]} items-center justify-center text-center`}
              >
                {fields.cta &&
                  fields.cta.map(cta => (
                    <ContentfulCTA
                      key={cta.sys.id}
                      content={cta}
                      className={buttonClassname}
                      {...inspectorProps({
                        entryId: content.sys.id,
                        fieldId: "name",
                      })}
                    />
                  ))}
              </div>
            </div>
          </div>
          {fields.toolTipLabel && fields.tooltip && (
            <div
              className={`absolute inset-x-0 bottom-4 inline-flex items-center justify-center gap-1 text-neutral-3 md:bottom-3`}
            >
              <TooltipWithRichText
                content={
                  <RichTextRender
                    content={fields.tooltip}
                    entryId={fields.tooltip.data.id}
                  />
                }
              >
                {/* The Icon is set as children, so we can use everything we want as a trigger for the Tooltip */}
                <InfoCircledIcon
                  height={16.67}
                  width={16.67}
                  className="cursor-pointer"
                  color="#141414"
                />
              </TooltipWithRichText>
              <p className="w-fit text-xs font-bold text-brand-primary-black">
                {fields.toolTipLabel}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
