import { Fragment } from "react";

import { Link, useLocation } from "@remix-run/react";

import { useURL } from "~/contexts";
import useHasMemberPriceFilter from "~/hooks/use-price-member-filter";
import { cn } from "~/lib/ui";

import { Skeleton } from "../../components/ui/skeleton";
import type { BapcorCategoryBreadcrumb } from "../.server/api/generated/__generated_apis";

export const BreadcrumbsSkeleton = ({ size = 2 }) => {
  return (
    <div className=" flex flex-row pb-2">
      <Skeleton className="h-5 w-8" />
      {Array.from({ length: size }, (_, i) => (
        <Fragment key={i}>
          <div className="w-2"></div>
          <Skeleton className="h-5 w-16" />
        </Fragment>
      ))}
    </div>
  );
};

const Breadcrumbs = ({
  categories,
  pageName,
  query,
  vehicleDesc,
}: {
  categories?: BapcorCategoryBreadcrumb[];
  pageName?: string;
  query?: string;
  vehicleDesc?: string;
}) => {
  const url = useURL();
  const location = useLocation();
  const isSearchPage = location.pathname.includes("/search");
  const { memberPriceFacet, hasMemberPriceFilter } = useHasMemberPriceFilter();

  return (
    <nav
      aria-label="Breadcrumb"
      className={cn(
        "flex flex-row flex-wrap items-center",
        "w-full sm:py-4 md:pt-8 lg:pb-4 lg:text-sm",
        "text-xs font-bold text-brand-primary-black",
      )}
    >
      <div className="flex flex-row items-center">
        <Link
          to={url("/")}
          className="text-brand-primary-black hover:underline"
          prefetch="intent"
        >
          Home
        </Link>
        <p className="text-brand-primary-black-500 mx-2">/</p>
      </div>
      {!query && vehicleDesc && isSearchPage && (
        <div className="flex flex-row items-center font-normal">
          {vehicleDesc}
        </div>
      )}
      {query && <div className="flex flex-row items-center ">{query}</div>}
      {pageName && !categories && (
        <div className="flex flex-row items-center font-normal ">
          {pageName}
        </div>
      )}
      {categories &&
        categories.map((category, i) => {
          if (category.name === "Autobarn Category") {
            return null;
          }
          if (i === categories.length - 1) {
            return (
              pageName && (
                <div key={pageName} className="font-normal no-underline">
                  {pageName}
                </div>
              )
            );
          }
          return (
            <div
              key={category.name + `-${i}`}
              className="flex flex-row items-center"
            >
              <Link
                to={url(
                  `${category.url}?hideVehicleComponent=${
                    category.hideVehicleComponent ?? "false"
                  }${
                    hasMemberPriceFilter
                      ? `&refine=${encodeURIComponent(
                          JSON.stringify(memberPriceFacet),
                        )}`
                      : ""
                  }` || "",
                )}
                className="hover:underline"
                prefetch="intent"
              >
                {category.name}
              </Link>
              <p className="mx-2 ">/</p>
            </div>
          );
        })}
    </nav>
  );
};

export default Breadcrumbs;
