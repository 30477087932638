import type { Toast } from "react-hot-toast";
import { toast } from "react-hot-toast";

import { Cross2Icon } from "@radix-ui/react-icons";

import { cn } from "~/lib/ui";

import { Text } from "../ui/text";

type Props = {
  message: string;
  t: Toast;
  className?: string;
};
const ToastError = ({ message, t, className }: Props) => {
  return (
    <div
      className={cn(
        "flex max-w-[312px] justify-between gap-4 rounded-lg border-2 border-error bg-danger p-4 shadow-lg",
        className,
      )}
    >
      <Text className="text-sm font-normal">{message}</Text>

      <button onClick={() => toast.remove()} className="self-start">
        <Cross2Icon width={24} height={24} />
      </button>
    </div>
  );
};

export default ToastError;
