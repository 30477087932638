import { forwardRef } from "react";

import { cn } from "~/lib/ui";

const TRANSPARENT_PIXEL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=";

export const getImageMediaLink = (
  imageUrl: string,
  {
    quality,
    params,
    resolutions = [1],
  }: {
    quality?: number;
    params: Record<string, string>;
    resolutions?: number[];
  },
) => {
  if (!imageUrl) return TRANSPARENT_PIXEL;

  try {
    const url = new URL(
      imageUrl.startsWith("//") ? "https:" + imageUrl : imageUrl,
    );

    let searchParams = url.searchParams;

    if (params) {
      searchParams = new URLSearchParams({
        ...Object.fromEntries(searchParams),
        ...params,
      });
    }

    const srcSet = resolutions
      .map(resolution => {
        const scaledParams = new URLSearchParams(searchParams);

        const adjustedQuality = resolution > 1 ? 75 : quality;

        if (adjustedQuality) {
          scaledParams.set("q", adjustedQuality.toString());
        }

        if (scaledParams.has("w")) {
          const width = parseInt(scaledParams.get("w") || "0", 10);
          const calculatedWidth = width * resolution;

          // Cap the width if it exceeds MAX_IMAGE_DIMENSION
          const finalWidth = Math.min(calculatedWidth, MAX_IMAGE_DIMENSION);

          // Validate finalWidth
          if (isNaN(finalWidth) || finalWidth <= 0) {
            console.warn(
              `Invalid finalWidth: ${finalWidth}. Falling back to default width.`,
            );
            scaledParams.set("w", "300");
          } else {
            scaledParams.set("w", finalWidth.toString());
          }
        }

        return `${url.origin + url.pathname}?${scaledParams.toString()}${
          resolution > 1 ? ` ${resolution}x` : ""
        }`;
      })
      .join(", ");

    return srcSet;
  } catch (error) {
    console.error("Error generating image media link:", error);
    return TRANSPARENT_PIXEL;
  }
};

const MAX_IMAGE_DIMENSION = 4000;

const calculateImageWidth = (
  breakpoint: number,
  numberOfColumns: number,
): number => {
  const gap = 20;
  const totalPadding = 2 * gap;
  const totalGap = (numberOfColumns - 1) * gap;

  const availableWidth = breakpoint - totalPadding - totalGap;

  const imageWidth = availableWidth / numberOfColumns;

  // Validate imageWidth
  if (isNaN(imageWidth) || imageWidth <= 0) {
    console.warn(
      `Invalid imageWidth calculated: ${imageWidth}. Falling back to default width.`,
    );
    return 300; // Default width in pixels
  }

  return imageWidth;
};

const calculateCappedWidth = (
  breakpoint: number,
  resolutions: number[],
  numberOfColumnsArray: [number, number, number],
): number => {
  const columns =
    breakpoint >= 1280
      ? numberOfColumnsArray[2]
      : breakpoint >= 768
        ? numberOfColumnsArray[1]
        : numberOfColumnsArray[0];

  // Ensure columns is a positive integer
  const validColumns = Number.isInteger(columns) && columns > 0 ? columns : 1;

  const baseWidth = calculateImageWidth(breakpoint, validColumns);
  const maxResolution = Math.max(...resolutions);

  const cappedWidth = Math.min(
    baseWidth,
    Math.floor(MAX_IMAGE_DIMENSION / maxResolution),
  );

  // Validate cappedWidth
  if (isNaN(cappedWidth) || cappedWidth <= 0) {
    console.warn(
      `Invalid cappedWidth calculated: ${cappedWidth}. Falling back to default width.`,
    );
    return 300; // Default width in pixels
  }

  return cappedWidth;
};

export const ContentfulImage = forwardRef<
  HTMLPictureElement,
  React.HTMLAttributes<HTMLPictureElement> & {
    imageUrl: string;
    mobileImageUrl?: string;
    alt: string;
    lazy?: boolean;
    small?: boolean;
    isBanner?: boolean;
    icon?: boolean;
    numberOfColumns?: [number, number, number];
  }
>(
  (
    {
      imageUrl,
      lazy = true,
      alt,
      small,
      isBanner = false,
      icon = false,
      numberOfColumns = [1, 1, 1],
      className,
      mobileImageUrl,
      ...props
    },
    ref,
  ) => {
    return imageUrl ? (
      <picture
        className={cn("contentful-image h-full w-full", className)}
        ref={ref}
        {...props}
      >
        {icon ? (
          // Icon images are served as a single 50px wide source
          <source
            srcSet={getImageMediaLink(imageUrl, {
              quality: 100,
              params: {
                w: "50",
                fm: "webp",
              },
              resolutions: [1, 2],
            })}
          />
        ) : small ? (
          // Small images are used for the product list page
          <source
            media="(min-width:767px)"
            srcSet={getImageMediaLink(imageUrl, {
              quality: 100,
              params: {
                w: calculateCappedWidth(
                  600,
                  [1, 2],
                  numberOfColumns,
                ).toString(),
                fm: "webp",
              },
              resolutions: [1, 2],
            })}
          />
        ) : isBanner ? (
          // Banner images with fixed width on mobile
          <source
            media="(max-width:767px)"
            srcSet={getImageMediaLink(mobileImageUrl || imageUrl, {
              quality: 85,
              params: {
                w: "767", // Fixed width for banners on mobile
                fm: "webp",
              },
              resolutions: [1, 2],
            })}
          />
        ) : (
          // Default image logic from 0px to 767px
          <>
            <source
              media="(max-width:375px)"
              srcSet={getImageMediaLink(mobileImageUrl || imageUrl, {
                quality: 85,
                params: {
                  w: calculateCappedWidth(
                    425,
                    [1, 2],
                    numberOfColumns,
                  ).toString(),
                  fm: "webp",
                },
                resolutions: [1, 2],
              })}
            />
            <source
              media="(max-width:425px)"
              srcSet={getImageMediaLink(mobileImageUrl || imageUrl, {
                quality: 85,
                params: {
                  w: calculateCappedWidth(
                    600,
                    [1, 2],
                    numberOfColumns,
                  ).toString(),
                  fm: "webp",
                },
                resolutions: [1, 2],
              })}
            />
            <source
              media="(max-width:600px)"
              srcSet={getImageMediaLink(mobileImageUrl || imageUrl, {
                quality: 85,
                params: {
                  w: calculateCappedWidth(
                    767,
                    [1, 2],
                    numberOfColumns,
                  ).toString(),
                  fm: "webp",
                },
                resolutions: [1, 2],
              })}
            />
            <source
              media="(max-width:767px)"
              srcSet={getImageMediaLink(mobileImageUrl || imageUrl, {
                quality: 85,
                params: {
                  w: calculateCappedWidth(
                    767,
                    [1, 2],
                    numberOfColumns,
                  ).toString(),
                  fm: "webp",
                },
                resolutions: [1, 2],
              })}
            />
          </>
        )}

        {!icon && (
          // Default image logic from 768px to infinity
          // only executes if icon === false
          <>
            <source
              media="(max-width:1279px)"
              srcSet={getImageMediaLink(imageUrl, {
                quality: 85,
                params: {
                  w: calculateCappedWidth(
                    1279,
                    [1, 2],
                    numberOfColumns,
                  ).toString(),
                  fm: "webp",
                },
                resolutions: [1, 2],
              })}
            />

            <source
              media="(max-width:1799px)"
              srcSet={getImageMediaLink(imageUrl, {
                quality: 75,
                params: {
                  w: calculateCappedWidth(
                    1799,
                    [1, 2],
                    numberOfColumns,
                  ).toString(),
                  fm: "webp",
                },
                resolutions: [1, 2],
              })}
            />
            <source
              media="(min-width:1800px)"
              srcSet={getImageMediaLink(imageUrl, {
                quality: 75,
                params: {
                  w: calculateCappedWidth(
                    2560,
                    [1, 2],
                    numberOfColumns,
                  ).toString(),
                  fm: "webp",
                },
                resolutions: [1, 2],
              })}
            />
          </>
        )}

        <img
          src={getImageMediaLink(imageUrl, {
            quality: 85,
            params: {
              w: calculateCappedWidth(800, [1, 2], numberOfColumns).toString(),
              fm: "webp",
            },
            resolutions: [1, 2],
          })}
          alt={alt}
          className={cn(
            "block h-full w-full object-cover object-center",
            className,
          )}
          loading={lazy === false ? "eager" : "lazy"}
          width={200}
          height={230}
        />
      </picture>
    ) : null;
  },
);

ContentfulImage.displayName = "ContentfulImage";
