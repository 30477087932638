import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useFetcher, useSearchParams } from "@remix-run/react";

import type { LocalDeliveryAddress } from "~/commerce-sap/.server/sessions.server";

import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";

import type {
  Locality,
  PointOfService,
  PointOfServiceStock,
} from "~/commerce-sap/.server/api/generated/__generated_apis";
import { isValidationError } from "~/components/forms/validationErrorResponse";
import { StoreLoadMore } from "~/components/store-locator/store-load-more";
import { Button } from "~/components/ui/button";
import PinIcon from "~/components/ui/icons/pin-icon";
import { SearchIcon } from "~/components/ui/icons/search-icon";
import { SearchAutoComplete } from "~/components/ui/search-autocomplete";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "~/components/ui/sheet";
import { Skeleton } from "~/components/ui/skeleton";
import { Switch } from "~/components/ui/switch";
import { P } from "~/components/ui/text";
import { TriButton } from "~/components/ui/tri-button";
import useAddressSuggestions from "~/hooks/use-address-suggestions";
import { useOnActionCompleted } from "~/lib/remix/fetcher";
import { cn } from "~/lib/ui";
import isMyStore from "~/lib/utils/is-my-store";
import { useMediaScreen } from "~/lib/utils/screens";
import { capitalize } from "~/lib/utils/utils";
import { useRootLoaderData } from "~/root";
import type { action } from "~/routes/($locale)+/_product-details+/($0).($1).($2).$productId[.html]";
import type { action as findStoresAction } from "~/routes/($locale)+/resources+/store+/find-stores-pdp";
import {
  showSetStoreToast,
  useSetStorePath,
} from "~/routes/($locale)+/resources+/store+/set-store";

import { useRootLayoutData } from "../../_layout";
import { useFindStoresPDPPath } from "../../resources+/store+/find-stores-pdp";
import type { loader } from "../../resources+/vehicle+/vehicle-manual.$type";
import { getStockLevelStatus } from "./pdp-store-finder";
import StockStatusMessage from "./stock-status-message";

export const FindStoreDrawerPDP = ({
  children,
  extractedItemCode,
  localDeliveryAddress,
  onStoreSelect,
  totalProducts,
}: {
  children: ReactNode;
  extractedItemCode: string;
  localDeliveryAddress?: LocalDeliveryAddress | null;
  onStoreSelect?: (store: PointOfServiceStock) => void;
  totalProducts?: number;
}) => {
  const { DefaultLowStockThreshold } = useRootLoaderData();
  const setStoreLocationPath = useSetStorePath();
  const getFindStoresPDPPath = useFindStoresPDPPath();

  const { myStoreInfo } = useRootLayoutData();
  const { addressValue, setAddressValue, isSearching, suggestions } =
    useAddressSuggestions();
  const fetcher = useFetcher<typeof action>();
  const fetcherFindStores = useFetcher<typeof findStoresAction>();
  const fetcherSetStore = useFetcher<typeof loader>();
  const { isMobile } = useMediaScreen();
  const [isOpen, setIsOpen] = useState(false);
  const [inStockOnlyStores, setInStockOnlyStores] = useState(false);
  const [localityCode, setLocalityCode] = useState<string>("");
  const [coordinates, setCoordinates] = useState<{
    lat: string;
    lng: string;
  }>(
    myStoreInfo?.store?.geoPoint?.latitude &&
      myStoreInfo?.store?.geoPoint?.longitude
      ? {
          lat: myStoreInfo?.store?.geoPoint?.latitude?.toString(),
          lng: myStoreInfo?.store?.geoPoint?.longitude?.toString(),
        }
      : { lat: "", lng: "" },
  );
  const [currentStores, setCurrentStores] = useState<PointOfServiceStock[]>([]);
  const [selectedStore, setSelectedStore] = useState<PointOfServiceStock>();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPageSize = +(searchParams.get("pageSize") || "5");
  const fetcherFindStoresLoading = fetcherFindStores.state !== "idle";

  useEffect(() => {
    const nextPageStores =
      (fetcherFindStores.data &&
        "stores" in fetcherFindStores.data &&
        Array.isArray(fetcherFindStores.data.stores) &&
        fetcherFindStores.data.stores) ||
      [];

    setCurrentStores([...currentStores, ...nextPageStores]);
  }, [fetcherFindStores.data]);

  const totalStores = useMemo(
    () =>
      (fetcherFindStores.data &&
        "totalStores" in fetcherFindStores.data &&
        (fetcherFindStores.data.totalStores as number)) ||
      0,
    [fetcherFindStores.data],
  );
  const isLoading = useMemo(() => fetcher.state !== "idle", [fetcher.state]);

  useEffect(() => {
    if (localDeliveryAddress) {
      const requestData = new FormData();
      requestData.set("query", `${localDeliveryAddress.postcode}`);
      fetch(
        `/resources/address/address-suggestions?_data=${encodeURIComponent(
          "routes/($locale)+/resources+/address+/address-suggestions",
        )}`,
        {
          method: "POST",
          body: requestData,
        },
      )
        .then<{ localities: Locality[] }>(response => response.json())
        .then(data => {
          if (data && data.localities) {
            const locality = data.localities.find(
              l =>
                l.postcode === localDeliveryAddress.postcode &&
                l.state === localDeliveryAddress.state &&
                l.suburb === localDeliveryAddress.suburb,
            );

            if (locality) {
              const { latitude, longitude, code } = locality;
              setCoordinates({ lat: latitude + "", lng: longitude + "" });
              setLocalityCode(code + "");
              setAddressValue(
                `${localDeliveryAddress.suburb} ${localDeliveryAddress.state} ${localDeliveryAddress.postcode}`,
              );
            }
          }
        })
        .catch(error => {
          console.error("Error:", error);
        });
    }
  }, []);

  const addressSelect = (value: string) => {
    const [lat, lng, _postcode, code] = value.split(";");
    if (!lat || !lng) {
      return;
    }
    setLocalityCode(code);
    setCoordinates({ lat: lat, lng: lng });
    setSearchParams(new URLSearchParams());
    setCurrentStores([]);
  };

  useEffect(() => {
    if (coordinates.lat && coordinates.lng) {
      fetcherFindStores.submit(
        {
          lat: +coordinates?.lat,
          lng: +coordinates?.lng,
          locationQuery: addressValue,
          localityCode,
          extractedItemCodes: JSON.stringify([extractedItemCode]),
          pageSize: currentPageSize,
          storesWithStockOnly: inStockOnlyStores,
        },
        { method: "post", action: getFindStoresPDPPath() },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageSize, coordinates, totalProducts, inStockOnlyStores]);

  const handleInStockSwitchChange = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("pageSize", "5");
    setSearchParams(newParams);
    setCurrentStores([]);
    setInStockOnlyStores(!inStockOnlyStores);
  };

  const handleSetStore = () => {
    if (
      !selectedStore ||
      !selectedStore?.geoPoint?.longitude ||
      !selectedStore?.geoPoint?.latitude
    ) {
      return;
    }

    const formData = new FormData();
    formData.set("longitude", selectedStore?.geoPoint?.longitude?.toString());
    formData.set("latitude", selectedStore?.geoPoint?.latitude?.toString());
    formData.set(
      "formattedDistance",
      selectedStore?.formattedDistance || "0km",
    );
    fetcherSetStore.submit(formData, {
      action: setStoreLocationPath(),
      method: "POST",
    });
    onStoreSelect && onStoreSelect(selectedStore);
  };

  useOnActionCompleted<{ success: boolean; message: string }>(
    setStoreLocationPath(),
    data => {
      if (isValidationError(data)) {
        return;
      }
      setIsOpen(false);
      showSetStoreToast(data, isMobile);
    },
  );

  const getStockStatus = useCallback(
    (store: PointOfService) => {
      const currentStore = currentStores.find(s => s.name === store.name);
      const availableQty = currentStore?.stockInfo?.stockLevel ?? 0;

      const stockStatus = getStockLevelStatus(
        availableQty,
        DefaultLowStockThreshold,
      );
      return <StockStatusMessage stockStatus={stockStatus} />;
    },
    [currentStores],
  );

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <div
        onClick={e => e.preventDefault()}
        onKeyDown={e => e.preventDefault()}
        tabIndex={-1}
        role="button"
      >
        <SheetTrigger asChild>{children}</SheetTrigger>
      </div>
      <SheetContent
        className="z-[100] flex h-dvh flex-col gap-0 bg-white sm:max-w-sm md:max-w-lg"
        side="right"
        onCloseAutoFocus={e => e.preventDefault()}
      >
        <div
          className={
            "relative z-50 flex h-[70px] w-full items-center gap-2 bg-brand-secondary py-[9px] pl-[18px] pr-6 align-middle"
          }
        >
          <div className="flex w-full items-center justify-between">
            <div className="flex max-w-sm items-center">
              <PinIcon />
              <Label className="text-base font-bold ">Find a Store</Label>
            </div>
            <SheetClose>
              <Cross2Icon
                color="#141414"
                width={32}
                height={32}
                viewBox="0 0 15.25 15.25"
              />
            </SheetClose>
          </div>
        </div>
        <div className="relative mb-[76px] overflow-y-scroll p-6 pb-32 pt-0 md:pb-6">
          <div className="sticky top-0 bg-white pt-6">
            <P>Search Postcode or Suburb</P>
            <div className="relative">
              <SearchAutoComplete
                data-testid="find-store-drawer-search-autocomplete"
                icon={
                  <div>
                    <SearchIcon
                      className={
                        "absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 transform text-muted-foreground"
                      }
                    />
                    {addressValue && (
                      <Button
                        onClick={() => setAddressValue("")}
                        variant="linkInline"
                        className=" absolute right-3 top-1/2 h-4 w-4 -translate-y-1/2 transform"
                      >
                        <Cross2Icon />
                      </Button>
                    )}
                  </div>
                }
                className={cn(
                  `w-full rounded-md border py-[17px] pl-10 pr-3 placeholder:text-neutral-4  focus-visible:border-2 focus-visible:border-brand-primary-black focus-visible:bg-neutral-10`,
                  `${
                    addressValue.length
                      ? "border-brand-primary-black px-10"
                      : "border-neutral-7"
                  }`,
                )}
                suggestions={
                  suggestions?.map(s => ({
                    value: `${s.latitude};${s.longitude};${s.postcode};${s.code}`,
                    label: `${capitalize(s.suburb ?? "")} ${s.state} ${
                      s.postcode
                    }`,
                  })) ?? []
                }
                onSearchValueChange={v => {
                  setAddressValue(v);
                }}
                searchValue={addressValue}
                onSelectedValueChange={v => {
                  addressSelect(v);
                }}
                selectedValue={addressValue}
                isLoading={isSearching}
              />
              <div className="my-2 flex py-2">
                <Switch
                  className="h-5"
                  checked={inStockOnlyStores}
                  onCheckedChange={handleInStockSwitchChange}
                />
                <span className="pl-2">
                  Show stores with in stock items only
                </span>
              </div>
            </div>
          </div>

          {currentStores.length !== 0 && (
            <div className="ma mt-4 flex flex-col gap-1">
              {currentStores.map((store, index) => (
                <div
                  aria-hidden="true"
                  key={index}
                  data-testid="store-card"
                  className={cn(
                    "shrink-0 overflow-hidden rounded border-2 bg-white",
                    `${
                      isMyStore(store, myStoreInfo)
                        ? "border-green-500"
                        : selectedStore &&
                            selectedStore.displayName === store.displayName
                          ? "border-brand-primary-black"
                          : "border-neutral-7"
                    }`,
                  )}
                  onClick={() => {
                    setSelectedStore(store);
                  }}
                >
                  <div className="flex flex-col gap-3 px-4 pb-4 pt-6 text-xs">
                    <div className="flex flex-row items-start justify-between align-top">
                      <Label className="text-base font-bold">
                        {store.displayName}
                      </Label>
                      <Label className="rounded-full bg-neutral-8 px-4 py-1">
                        {store.formattedDistance || "0 Km"}
                      </Label>
                    </div>
                    <p className="max-w-[204px] text-sm">
                      {store.address?.formattedAddress}
                    </p>
                    <div
                      className={`flex items-center${
                        isMyStore(store, myStoreInfo)
                          ? " justify-between"
                          : "justify-start"
                      }`}
                    >
                      <div className="flex items-center	gap-1">
                        {getStockStatus(store)}
                      </div>
                      {isMyStore(store, myStoreInfo) && (
                        <div className="flex items-center gap-1">
                          <CheckCircleIcon className="inline h-5 fill-brand-primary" />
                          My Store
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {currentStores.length < (totalStores || 0) &&
                !fetcherFindStoresLoading && (
                  <StoreLoadMore
                    stores={currentStores}
                    totalStores={totalStores}
                    isLoading={isLoading || fetcherFindStoresLoading}
                    pageSize={currentPageSize}
                    coordinates={coordinates}
                  />
                )}
            </div>
          )}
          {fetcherFindStoresLoading && (
            <Skeleton className="my-2 h-32 w-full" />
          )}
        </div>
        <div className="absolute bottom-0 w-full bg-white px-6 py-4 shadow-vehicleSearchDrawer">
          <TriButton
            className="bottom-0 h-11 w-full text-base font-bold text-neutral-100"
            type="button"
            data-testid="set-store-button"
            onClick={handleSetStore}
            loadingIndicatorClassName="fill-brand-primary-black"
            isLoading={fetcherSetStore.state !== "idle"}
            disabled={
              !selectedStore?.geoPoint || fetcherSetStore.state !== "idle"
            }
          >
            Set As My Store
          </TriButton>
        </div>
      </SheetContent>
    </Sheet>
  );
};
