import type { ReactNode } from "react";
import { createContext, useCallback, useEffect, useMemo, useRef } from "react";

import type { SerializeFrom } from "@remix-run/cloudflare";
import { useFetcher } from "@remix-run/react";

import { useURL } from "~/contexts";

import { BASKET_RESOURCE_URL, type loader } from "../basket";

const BasketContext = createContext<
  | ({
      openBasket: () => void;
      refetchProducts: () => void;
      ref: React.MutableRefObject<{ open?: () => void }>;
    } & SerializeFrom<typeof loader>)
  | undefined
>(undefined);

export const BasketProvider = ({ children }: { children: ReactNode }) => {
  const url = useURL();
  const fetcher = useFetcher<typeof loader>();
  const ref = useRef({});

  const refetchProducts = useCallback(() => {
    fetcher.load(url(BASKET_RESOURCE_URL));
  }, []);

  useEffect(() => {
    fetcher.load(url(BASKET_RESOURCE_URL));
  }, []);

  useEffect(() => {
    const { basket } = fetcher.data || {};
    if (basket) {
      sessionStorage.setItem("cartid", basket.code || "");
      sessionStorage.setItem("guid", basket.guid || "");
    }
  }, [fetcher.data]);

  const openBasket = useCallback(() => {
    if (
      ref.current &&
      "open" in ref.current &&
      typeof ref.current.open === "function"
    )
      ref.current.open();
  }, []);

  const data = useMemo(() => {
    if (!fetcher.data) return undefined;

    return {
      basket: fetcher.data.basket!,
      miniCartStockData: fetcher.data.miniCartStockData,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher.data]);

  return (
    <BasketContext.Provider
      value={data ? { ...data, openBasket, refetchProducts, ref } : undefined}
    >
      {children}
    </BasketContext.Provider>
  );
};

export default BasketContext;
