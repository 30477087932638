import { memo, useCallback, useEffect, useRef, useState } from "react";

import { getMaxWidthClass } from "~/contentful/util";
import { cn } from "~/lib/ui";
import { useMediaScreen } from "~/lib/utils/screens";
import ProductTile from "~/routes/($locale)+/product-list+/components/product-tile/product-tile";
import { useProductRecommendations } from "~/routes/($locale)+/resources+/product-recommendations";

import { Carousel } from "../ui/carousel";
import { Skeleton } from "../ui/skeleton";
import { H2 } from "../ui/text";

type Props = {
  sysId: string;
  fields:
    | {
        name: string;
        heading?: string;
        categoryId: string;
        maxWidth?: 1280 | 1920 | 2560 | 768;
      }
    | {
        name: string;
        heading?: string;
        productIDs: string[];
        maxWidth?: 1280 | 1920 | 2560 | 768;
      };
};

const ProductRecommendations = memo(({ sysId, fields }: Props) => {
  const { isDesktop, isTablet } = useMediaScreen();
  const spaceBetweenSlides = useRef<number>(12);

  const pickSpaceBetween = useCallback(() => {
    switch (true) {
      case isDesktop:
        spaceBetweenSlides.current = 20;
        break;
      case isTablet:
        spaceBetweenSlides.current = 16;
        break;
      default:
        spaceBetweenSlides.current = 12;
        break;
    }
    return spaceBetweenSlides.current;
  }, [isDesktop, isTablet]);

  const [spaceBetween, setSpaceBetween] = useState<number>(pickSpaceBetween());

  useEffect(() => {
    setSpaceBetween(pickSpaceBetween());
  }, [pickSpaceBetween]);

  const { isLoading, data } = useProductRecommendations({
    sysId: sysId,
    categoryId: "categoryId" in fields ? fields.categoryId : "",
    productIDs: "productIDs" in fields ? fields.productIDs : [],
  });

  /* only show Skeleton if we don't have data to avoid flicker while this is been revalidated */
  if (isLoading && !data)
    return <Skeleton className="h-84 w-full md:h-[500px]" />;

  if (!data || !data.algoliaResult?.hits?.length) return null;

  const maxWidthClass = getMaxWidthClass(fields.maxWidth);

  const sortedAlgoliaResultHits =
    "productIDs" in fields
      ? fields.productIDs
          .flatMap(id => data.algoliaResult.hits.filter(hit => hit.code === id))
          .filter(Boolean)
      : data.algoliaResult.hits;

  return (
    <div
      className={cn(
        `product-recommender mx-auto `,
        `overflow-x-hidden`,
        `${maxWidthClass}`,
      )}
    >
      <div className="">
        {fields.heading && (
          <H2 className="font-roboto text-[26px] font-semibold uppercase leading-lg text-brand-primary-black lg:text-[32px]">
            {fields.heading}
          </H2>
        )}
      </div>
      <Carousel
        className={cn("product-recommender-slider", "!ml-0 !mr-auto  !w-[95%]")}
        spaceBetween={spaceBetween}
        slidesPerView={"auto"}
        centeredSlides={false}
        useBubblesNavigation
        watchSlidesProgress
        watchOverflow
        loop={false}
        slideClassName="max-w-[320px]"
        slideProps={{
          style: { height: "auto" },
        }}
        hideControlsOnMobile={true}
        isHeroBanner={false}
      >
        {sortedAlgoliaResultHits.map((hit, i: number) => (
          <ProductTile
            product={hit}
            key={`recommendation-${hit.code}-${i}`}
            list_id="recommendations"
            list_name="Recommendations"
            index={i}
            fitmentData={data.fitmentData ?? {}}
            showSuitability={data.showSuitability ?? false}
            className="product-tile h-full w-full self-stretch px-0 py-[0] text-left "
            isCarousel={true}
          />
        ))}
      </Carousel>
    </div>
  );
});
ProductRecommendations.displayName = "ProductRecommendations";

export default ProductRecommendations;
