export const ACCOUNT_PAGES = {
  personalDetails: {
    id: "personal-details",
    name: "My Details",
    path: "/account/personal-details",
  },
  addresses: {
    id: "addresses",
    name: "My Address Book",
    path: "/account/addresses",
  },
  orders: {
    id: "orders",
    name: "My Orders",
    path: "/account/orders",
  },
  dashboard: {
    id: "dashboard",
    name: "My account",
    path: "/account",
  },
  acceleratedRewards: {
    id: "accelerate-rewards",
    name: "My Accelerate Rewards",
    path: "/account/accelerate-rewards",
  },
};
export const CHECKOUT_PAGES = {
  checkout: "/checkout",
  checkoutDelivery: "/checkout-delivery",
  checkoutPayment: "/checkout-payment",
  checkoutDetailsRedirect: "/checkout?edit=true",
};
export const TERMS_AND_CONDITIONS_URL = "/pages/terms-and-conditions";
export const ACCELERATE_REWARDS_TERMS_URL = "/pages/accelerate-rewards";
export const ACCOUNT_LANDING_PAGE_URL = "/account/accelerate-rewards";

export const ICON_ACTIVE_COLOR = "#93D500";

export const STATUS_COLORS = {
  complete: "bg-success",
  closed: "bg-success",
  processing: "bg-info",
  cancelled: "bg-success",
  collected: "bg-success",
  created: "bg-info",
  picked: "bg-info",
  refund: "bg-success",
  shipped: "bg-info",
  partiallyRefunded: "bg-success",
  readyshipping: "bg-info",
  readycollection: "bg-info",
  other: "bg-amber",
};

export const STATUS_MESSAGES = {
  complete: "completed",
  processing: "processing",
  cancelled: "cancelled",
  collected: "collected",
  created: "created",
  readyshipping: "picked",
  readycollection: "picked",
  closed: "completed",
  refund: "refund",
  shipped: "shipped",
  partially_refunded: "processing",
  other: "other",
};

export const ADDRESS_STATES = [
  "ACT",
  "NSW",
  "NT",
  "QLD",
  "SA",
  "TAS",
  "VIC",
  "WA",
];

export const dialingCode = "+61";
export const PASSWORD_NOT_MATCH_MESSAGE =
  "Your current password doesn't match your saved password";
export const MOST_RECENT_PASSWORD_NOT_ALLOWED_MESSAGE =
  "You're not allowed to re-use your most recent password.";

export const PASSWORD_NOT_MATCH_CODE = "403042";
export const MOST_RECENT_PASSWORD_NOT_ALLOWED_CODE = "401030";
