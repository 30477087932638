// FitmentButton.js
// Assuming this icon is in the same folder
import { GlobeAltIcon } from "@heroicons/react/20/solid";

import { CustomExclamationCircleIcon } from "~/components/meganav/custom-exclamation-circle-icon";

interface ProductFitmentButtonProps {
  fitmentSpecific: boolean | undefined;
  handleOpenVehicleSearch?: () => void;
}

const ProductFitmentButton: React.FC<ProductFitmentButtonProps> = ({
  fitmentSpecific,
  handleOpenVehicleSearch,
}) => {
  return fitmentSpecific ? (
    <button
      className="flex h-14 w-full flex-row items-center justify-center gap-1 rounded-t-[4px] border-t-[3px] border-yellow-500 bg-yellow-200 p-2 font-bold"
      onClick={handleOpenVehicleSearch}
    >
      <div className="relative block pl-[3px]">
        <img src="/icons/car.svg" alt="car icon" className="w-9" />
        <div className="absolute right-[-2px] top-[-4px] flex h-4 w-4 items-center justify-center rounded-full bg-brand-primary-black">
          <CustomExclamationCircleIcon stroke="#fef08a" />
        </div>
      </div>
      Check Suitability
    </button>
  ) : (
    <button
      className="flex h-14 w-full flex-row items-center justify-center gap-1 rounded-t-[4px] border-t-[3px] border-gray-500 bg-neutral-9 p-2 font-bold"
      onClick={handleOpenVehicleSearch}
    >
      <div className="relative block pl-[3px]">
        <img src="/icons/car.svg" alt="car icon" className="w-9" />
        <div className="absolute right-[-2px] top-[-4px] flex h-4 w-4 items-center justify-center rounded-full bg-brand-primary-black">
          <GlobeAltIcon
            aria-hidden="true"
            stroke=""
            fill="#f2f2f2"
            style={{ width: "16px", height: "16px" }}
          />
        </div>
      </div>
      Universal Part
    </button>
  );
};

export default ProductFitmentButton;
