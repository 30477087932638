import { json } from "@remix-run/cloudflare";

import { validationError } from "~/components/forms/validationErrorResponse.server";

import { useURL } from "~/contexts";

export const loader = () => {};

export const action = async ({ request, context }: ActionArgs) => {
  const formData = await request.formData();
  const { api } = context;

  const pageSize = formData.get("pageSize");
  const lat = formData.get("lat");
  const lng = formData.get("lng");
  const locationQuery = formData.get("locationQuery");
  const storesWithStockOnly =
    formData.get("storesWithStockOnly") === "true" ? true : false;
  const extractedItemCodes = JSON.parse(
    formData.get("extractedItemCodes") + "" || "[]",
  );

  if (
    typeof lat === "string" &&
    typeof lng === "string" &&
    typeof locationQuery === "string"
  ) {
    try {
      const currentPage = (pageSize ? Number(pageSize) : 5) / 5;

      const storeData = await api.productStockCheck({
        productCode: extractedItemCodes[0],
        latitude: +lat,
        longitude: +lng,
        locationQuery: locationQuery,
        page: currentPage - 1,
        storesWithStockOnly: storesWithStockOnly,
      });

      return json({
        stores: storeData.stores ?? [],
        totalStores: storeData.pagination?.totalResults,
      });
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.stack || error);

        return validationError(
          "Something went wrong. Please try selecting the address again.",
        );
      }
      throw error;
    }
  }

  return null;
};

export const useFindStoresPDPPath = () => {
  const _url = useURL();
  return () => _url(`/resources/store/find-stores-pdp`);
};
