import type { MyStoreInfo } from "~/commerce-sap/.server/sessions.server";

import type { PointOfServiceStock } from "~/commerce-sap/.server/api/generated/__generated_apis";

const isMyStore = (store: PointOfServiceStock, myStoreInfo?: MyStoreInfo) => {
  if (!store || !myStoreInfo) {
    return false;
  }

  return (
    store.geoPoint?.latitude === myStoreInfo.store?.geoPoint?.latitude &&
    store.geoPoint?.longitude === myStoreInfo.store?.geoPoint?.longitude
  );
};

export default isMyStore;
