import * as React from "react";

import { PrefetchPageLinks, useNavigate } from "@remix-run/react";

const context = React.createContext(false);

export function isLinkEvent(event: MouseEvent) {
  if (!(event.target instanceof HTMLElement)) return;
  const a = event.target.closest("a");
  /* we dont want to handle Links */
  if (
    a?.hasAttribute("data-discover") &&
    a?.getAttribute("data-discover") === "true"
  ) {
    return;
  }
  if (
    a?.hasAttribute("data-no-delegate-anchor") &&
    a?.getAttribute("data-no-delegate-anchor") === "true"
  ) {
    return;
  }
  if (a?.hasAttribute("href") && a.host === window.location.host) return a;
  return;
}

export type DelegatedAnchorsOptions = {
  preventScrollRestoration?:
    | boolean
    | ((url: string, e: MouseEvent) => boolean);
  prefetch?: boolean;
  replace?: boolean;
};

export function useDelegatedAnchors(
  nodeRef: React.RefObject<HTMLElement>,
  options: DelegatedAnchorsOptions = {},
) {
  const navigate = useNavigate();

  const hasParentPrefetch = React.useContext(context);

  React.useEffect(() => {
    const { preventScrollRestoration, replace } = options;
    if (hasParentPrefetch) return;

    const node = nodeRef.current;

    node?.addEventListener("click", handleClick);
    return () => node?.removeEventListener("click", handleClick);

    function handleClick(event: MouseEvent) {
      if (!node) return;

      const anchor = isLinkEvent(event);

      if (!anchor) return;
      if (event.button !== 0) return;
      if (anchor.target && anchor.target !== "_self") return;
      if (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey) {
        return;
      }

      if (anchor.hasAttribute("download")) return;

      const { pathname, search, hash, href } = anchor;

      const preventScrollReset =
        typeof preventScrollRestoration === "function"
          ? preventScrollRestoration(href, event)
          : (preventScrollRestoration ?? false);

      navigate(
        { pathname, search, hash },
        {
          preventScrollReset,
          replace,
        },
      );

      event.preventDefault();
    }
  }, [hasParentPrefetch, navigate, nodeRef, options]);
}

export function PrefetchPageAnchors({
  children,
  options,
}: {
  children: React.ReactNode;
  options?: DelegatedAnchorsOptions;
}) {
  const nodeRef = React.useRef<HTMLDivElement>(null);
  const [page, setPage] = React.useState<null | string>(null);
  const hasParentPrefetch = React.useContext(context);

  // prefetch is useless without delegated anchors, so we enable it
  useDelegatedAnchors(nodeRef, options);

  React.useEffect(() => {
    if (hasParentPrefetch) return;

    const node = nodeRef.current;

    node?.addEventListener("mouseenter", handleMouseEnter, true);
    return () => node?.removeEventListener("mouseenter", handleMouseEnter);

    function handleMouseEnter(event: MouseEvent) {
      if (!nodeRef.current) return;
      const anchor = isLinkEvent(event);
      if (!anchor) return;

      const { pathname, search } = anchor;
      setPage(pathname + search);
    }
  }, [hasParentPrefetch]);

  return (
    <div ref={nodeRef} style={{ display: "contents" }}>
      <context.Provider value={true}>{children}</context.Provider>
      {page && !hasParentPrefetch && <PrefetchPageLinks page={page} />}
    </div>
  );
}
