import { useEffect, useState } from "react";

import { useFetcher } from "@remix-run/react";

import type { Locality } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useDebounce } from "~/lib/ui";
import type { action as addressSuggestionsAction } from "~/routes/($locale)+/resources+/address+/address-suggestions";

const useAddressSuggestions = () => {
  const [addressValue, setAddressValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Locality[] | null>(null);

  const fetcherSearch = useFetcher<typeof addressSuggestionsAction>();
  const isSearching = fetcherSearch.state !== "idle";

  const debouncedAddressValue = useDebounce(addressValue, 300);

  useEffect(() => {
    if (debouncedAddressValue && debouncedAddressValue.length >= 3) {
      fetcherSearch.submit(
        { query: debouncedAddressValue },
        { method: "post", action: "/resources/address/address-suggestions" },
      );
    } else {
      setSuggestions(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddressValue]);

  useEffect(() => {
    if (fetcherSearch.data && "localities" in fetcherSearch.data) {
      setSuggestions(
        fetcherSearch.data?.localities ? fetcherSearch.data?.localities : null,
      );
    }
  }, [fetcherSearch.data]);

  return {
    addressValue,
    setAddressValue,
    isSearching,
    suggestions,
  };
};

export default useAddressSuggestions;
