import { memo, useState } from "react";

import { useNavigate } from "@remix-run/react";

import { GlobeAltIcon } from "@heroicons/react/20/solid";
import { CrossCircledIcon } from "@radix-ui/react-icons";

import type { BapcorFitment } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { CustomCheckCircleIcon } from "~/components/meganav/custom-checkmark-icon";
import { cn } from "~/lib/ui";

import FitmentPartDetail from "./product-fitment-part-detail";

type ProductTileAdditionalContentProps = {
  fitmentDataParts?: BapcorFitment[] | null;
  isFitmentSpecific?: boolean;
};

const ProductTileAdditionalContent = memo(
  ({
    fitmentDataParts,
    isFitmentSpecific,
  }: ProductTileAdditionalContentProps) => {
    const [showMore, setShowMore] = useState(false);
    const navigate = useNavigate();

    const handleOpenVehicleSearch = () => {
      navigate(`${window.location.search}#drawer=vehicle`, {
        preventScrollReset: true,
        replace: true,
      });
    };

    const toggleShowMore = () => {
      setShowMore(!showMore);
    };

    // Use the already filtered fitment data
    const displayedFitmentData = showMore
      ? fitmentDataParts
      : fitmentDataParts?.slice(0, 1);
    const hasFitment = fitmentDataParts && fitmentDataParts.length > 0;

    return (
      <div className="flex w-full flex-col">
        {!isFitmentSpecific ? (
          <button
            onClick={handleOpenVehicleSearch}
            className="flex h-14 w-full flex-row items-center justify-center gap-1 rounded-t-[4px] border-t-[3px] border-gray-500 bg-neutral-9 p-2 font-bold"
          >
            <div className="relative block pl-[3px]">
              <img src="/icons/car.svg" alt="car icon" className="w-9" />
              <div className="absolute right-[-2px] top-[-4px] flex h-4 w-4 items-center justify-center rounded-full bg-brand-primary-black">
                <GlobeAltIcon aria-hidden="true" stroke="" fill="#f2f2f2" />
              </div>
            </div>
            <span className="pt-1 text-sm">Universal Part</span>
          </button>
        ) : (
          <button
            onClick={handleOpenVehicleSearch}
            className={cn(
              "flex h-14 flex-row items-center justify-center gap-1 rounded-t-[4px] border-t-[3px] bg-opacity-5 p-2 text-sm font-normal",
              hasFitment
                ? "border-success bg-success"
                : "border-error bg-error",
            )}
          >
            <div className="relative pl-[3px]">
              <img src="/icons/car.svg" alt="car icon" className="w-9" />
              <div className="absolute right-[-2px] top-[-4px] flex h-4 w-4 items-center justify-center rounded-full bg-brand-primary-black">
                {hasFitment ? (
                  <CustomCheckCircleIcon
                    width={32}
                    height={22}
                    stroke="white"
                    fill="#4caf50"
                  />
                ) : (
                  <CrossCircledIcon
                    width={18}
                    height={16}
                    aria-hidden="true"
                    stroke="white"
                    className="rounded-lg bg-error"
                  />
                )}
              </div>
            </div>
            <span className="pt-1 text-sm font-normal">
              {hasFitment ? "Fits your Vehicle" : "Does Not Fit Your Vehicle"}
            </span>
          </button>
        )}

        <div className="bg-neutral-9">
          {/* Render unique fitment data */}
          {hasFitment &&
            displayedFitmentData?.map((fitment, index) => (
              <div
                key={index}
                className="flex flex-col gap-1 pb-2 pl-2 pr-2 pt-4 text-xs"
              >
                <div className="font-bold">
                  {fitment.subCatDescription || "Part Description"}
                </div>
                <FitmentPartDetail label="Footnote" data={fitment.footnote} />
                <FitmentPartDetail label="Grade" data={fitment.grade} />
                {fitment.pcq && <div>Per Car QTY: {fitment.pcq}</div>}
              </div>
            ))}

          {hasFitment && fitmentDataParts?.length > 1 && (
            <div className="mb-4 ml-2 mt-0.5">
              <button
                className="text-xs font-bold underline"
                onClick={toggleShowMore}
              >
                {showMore ? "See Less" : "See More"}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  },
);

ProductTileAdditionalContent.displayName = "ProductTileAdditionalContent";
export default ProductTileAdditionalContent;
